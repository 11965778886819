import * as React from 'react';
import { graphql } from 'gatsby';
import './styles.scss';
import {BasePageData, BasePageFooter, BasePageHeader} from '../helpers/base-page';
import {TitleSection} from '../components/features/title-section';
import {MarkdownRemark} from '../models/markdown-remark';
import {ModuleSection} from '../components/features/module-section';
import {useState} from 'react';

interface PricingPageData extends BasePageData {
  featuresData: MarkdownRemark<{
    seoTitle: string;
    title: string;
    subLabel: string;
    ModuleList: Array<{
      moduleName: string;
      sectionBackground?: string;
    }>
  }>;
}

const FeaturesPage = ({data}: {data: PricingPageData}): JSX.Element => {

  const [query, setQuery] = useState('');
  const [isFormVisible, setFormVisibility] = useState(false);

  const moduleList = data.featuresData.edges[0].node.frontmatter.ModuleList
    .map(module => ({
      ...module,
      moduleFeatures: data.features.edges
        .map(f => f.node.frontmatter)
        .filter(f => f.moduleName === module.moduleName)
        .filter(feature => feature.title.toLowerCase().includes(query.toLowerCase()) || feature.text.toLowerCase().includes(query.toLowerCase()))
    }))
    .filter(module => module.moduleFeatures.length > 0);

  return (
    <React.Fragment>
      <BasePageHeader
        siteTitle={data.featuresData.edges[0].node.frontmatter.seoTitle ?? data.metaInfo.edges[0].node.frontmatter.siteTitle}
        siteDescription={data.metaInfo.edges[0].node.frontmatter.siteDescription}
        siteDescriptionTitle={data.metaInfo.edges[0].node.frontmatter.siteDescriptionTitle}
        siteImage={data.metaInfo.edges[0].node.frontmatter.siteImage}
        siteUrl={data.metaInfo.edges[0].node.frontmatter.siteUrl}
        logoLight={data.logos.edges[0].node.frontmatter.logoHeaderLight}
        logoDark={data.logos.edges[0].node.frontmatter.logoHeaderDark}
        logoShort={data.logos.edges[0].node.frontmatter.logoShort}
        links={data.navigation.edges[0].node.frontmatter.topCenterNavigation}
        modules={data.modules.edges.map(m => m.node.frontmatter)}
        usecases={data.usecases.edges.map(u => u.node.frontmatter)}
        hubspotFormId={data.hubspot.edges[0].node.frontmatter.hubSpotFormId}
        hubspotPortalId={data.hubspot.edges[0].node.frontmatter.hubSpotPortalId}
        contactFormConfig={data.contactFormConfig.edges[0].node.frontmatter}
        isFormVisible={isFormVisible}
        onFormClose={() => setFormVisibility(false)}
      />
      <TitleSection
        title={data.featuresData.edges[0].node.frontmatter.title}
        text={data.featuresData.edges[0].node.frontmatter.subLabel}
        modules={data.modules.edges.map(m => m.node.frontmatter)}
        onSearch={query => setQuery(query)}
      />
      {
        moduleList.map((module, index, arr) => {
          const moduleOrigin = data.modules.edges
            .map(m => m.node.frontmatter)
            .find(m => m.name === module.moduleName);
          return (
            <ModuleSection
              key={index}
              module={moduleOrigin}
              background={module.sectionBackground}
              features={module.moduleFeatures}
              onOpenForm={() => setFormVisibility(true)}
            />
          );
        })
      }
      <div style={{height: 48}}/>
      <BasePageFooter
        feedbackTitle={data.contact.edges[0].node.frontmatter.title}
        feedbackLabel={data.contact.edges[0].node.frontmatter.subText}
        feedbackButtonText={data.contact.edges[0].node.frontmatter.buttonText}
        feedbackInputPlaceholder={data.contact.edges[0].node.frontmatter.inputPlaceholder}
        navigation={data.navigation}
        integrations={data.integrations}
        blogArticles={data.blogArticles}
        blogCategories={data.blogCategories}
        features={data.features.edges.map(f => f.node.frontmatter)}
        footerLogo={data.logos.edges[0].node.frontmatter.logoFooter}
      />
    </React.Fragment>
  );
};

export default FeaturesPage;

export const pageQuery = graphql`
  {
    ...BaseData
    featuresData: allMarkdownRemark(filter: {
      fileAbsolutePath: {
        regex: "/site/content/features/features.md$/"
      }
    }) {
      edges {
        node {
          frontmatter {
            seoTitle
            title
            subLabel
            ModuleList {
              moduleName
              sectionBackground
            }
          }
        }
      }
    }
  }
`;
