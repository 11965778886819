import React, {CSSProperties} from 'react';
import Background from '../../images/product-background.png';
import styled from 'styled-components';
import {ModuleInfo} from '../../models/module-info';
import SearchIcon from '../../images/search.svg';
import ReactMarkdown from 'react-markdown';
import {HSL} from '../../helpers/hsl';
import {device} from '../../models/devices';

const Container = styled.section`
  background: url(${Background}) no-repeat bottom center;
  background-size: cover;
  padding-bottom: 152px;
  padding-top: 190px;

  @media screen and ${device.tablet} {
    padding: 115px 24px 80px;
  }
`;

const Wrapper = styled.div`
  max-width: 1170px;
  margin: 0 auto;
`;

const Header = styled.h1`
  font-size: 48px;
  font-weight: 500;
  line-height: 1.33;
  color: white;
  grid-area: header;
  margin: 0 auto 32px;
  text-align: center;
  max-width: 970px;
  letter-spacing: -0.2px;

  @media screen and ${device.tablet} {
    font-size: 32px;
    line-height: 1.5em;
    text-align: left;
    margin-bottom: 24px;
    max-width: initial;
  }
`;

const Text = styled.div`
  font-size: 18px;
  font-weight: 500;
  line-height: 1.78;
  color: #cfe7fc;
  grid-area: text;
  letter-spacing: -0.1px;
  margin-bottom: 24px;
  text-align: center;

  @media screen and ${device.tablet} {
    font-size: 16px;
    line-height: 28px;
    text-align: left;
    margin-bottom: 32px;
  }
`;

const modulesContainerStyle: CSSProperties = {
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  margin: '0 auto',
  maxWidth: 844
};

const InputWrapper = styled.div`
  border-radius: 10px;
  overflow: hidden;
  margin: 0 auto 32px;
  max-width: 554px;
  position: relative;

  &:before {
    content: '';
    display: inline-block;
    width: 6px;
    height: 100%;
    background: #00e0dd;
    position: absolute;
  }

  input {
    background: #0089ff;
    border: none;
    //border-radius: 10px;
    font-size: 16px;
    font-weight: 700;
    color: white;
    letter-spacing: 0.9px;
    padding: 18px 50px 18px 31px;
    outline: none;
    width: 100%;
    box-sizing: border-box;

    ::placeholder {
      color: white;
    }
  }

  img {
    position: absolute;
    right: 24px;
    top: 17px;
  }
`;

const Link = styled.a<{color: string;}>`
  height: 56px;
  border-radius: 28px;
  background: white;
  box-shadow: -1px 5px 24px 0 rgba(181, 187, 203, 0.21);
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0 15px 24px;
  width: 249px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-left: 16px;
  color: black;
  text-decoration: none;
  
  &:hover {
    background-color: ${props => HSL.fromHex(props.color).setL(85).toHex()};
  }
`;

export const TitleSection = (props: {
  title: string;
  text: string;
  modules: Array<ModuleInfo>;
  onSearch?: (query: string) => void
}): JSX.Element => {
  return (
    <Container>
      <Wrapper>
        <Header><ReactMarkdown>{props.title}</ReactMarkdown></Header>
        <Text><ReactMarkdown>{props.text}</ReactMarkdown></Text>
        <InputWrapper>
          <input
            type="text"
            placeholder="Search Features"
            onChange={e => props.onSearch?.(e.target.value)}
          />
          <img src={SearchIcon} alt=""/>
        </InputWrapper>
        <div style={modulesContainerStyle}>
          {
            props.modules.map((module, index) => (
              <Link color={module.color} href={`#${module.name}`} key={index}>
                <img style={{marginRight: 8, width: 32, height: 32}} src={module.icon} alt={module.altText}/>
                <span style={{fontSize: 14, fontWeight: 600}}>{module.name}</span>
              </Link>
            ))
          }
        </div>
      </Wrapper>
    </Container>
  );
};
