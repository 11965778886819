import React from 'react';
import {ModuleInfo} from '../../models/module-info';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import {Feature} from '../../models/feature';
import { Button } from '../shared/button';
import {device} from '../../models/devices';

const Container = styled.section`
  padding-top: 120px;
  padding-bottom: 138px;

  @media screen and ${device.tablet} {
    padding-top: 80px;
    padding-bottom: 80px;
  }
`;

const Wrapper = styled.div`
  max-width: 1170px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 335px 1fr;
  
  @media screen and ${device.tablet} {
    grid-template-columns: 1fr;
    padding: 0 24px;
  }
`;

const FeatureGrid = styled.div`
  display: grid;
  grid-gap: 31px;
  grid-template-columns: repeat(4, 170px);
  padding-left: 65px;

  img {
    max-width: 100%;
    height: 156px;
    margin: 0 auto 9px;
  }

  @media screen and ${device.tablet} {
    grid-template-columns: 1fr;
    padding: 0;
  }
`;

const Image = styled.div<{src: string;}>`
  width: 100%;
  height: 156px;
  background: url(${props => props.src}) no-repeat center;
  background-size: contain;

  &:hover {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
    transition: .3s;
  }

  @media screen and ${device.tablet} {
    background-position-x: left;
  }
`;

const ModuleInfoBlock = styled.div`
  border-right: 1px solid #e6ecfb;
  padding-right: 51px;

  @media screen and ${device.tablet} {
    border: none;
    padding: 0;
    margin-bottom: 48px;
  }
`;

export const ModuleSection = (props: {
  module?: ModuleInfo;
  background?: string;
  features: Array<Feature>
  onOpenForm?: () => void
}): JSX.Element => {
  return (
    <Container id={props.module?.name} style={{background: `url(${props.background})`}}>
      <Wrapper>
        <ModuleInfoBlock>
          <span style={{
            fontSize: 24,
            fontWeight: 600,
            display: 'inline',
            boxShadow: `inset 0 -0.5em ${props.module?.color}`
          }}>
            {props.module?.name}
          </span>
          <div style={{fontSize: 15, color: '#54595a', marginTop: 16, marginBottom: 32}}>
            { props.module && <ReactMarkdown>{props.module?.description}</ReactMarkdown> }
          </div>
          <Button color={'violet'} buttonStyle={'default'} onClick={() => props.onOpenForm?.()}>Get Started</Button>
        </ModuleInfoBlock>
        <FeatureGrid>
          {
            props.features.map((feature, index) => (
              <div key={index}>
                {feature.img && <Image src={feature.img}/>}
                <div style={{fontSize: 18, fontWeight: 500, color: '#4f40ab', marginBottom: 16}}>
                  <ReactMarkdown>{feature.title}</ReactMarkdown>
                </div>
                <div style={{fontSize: 16, color: '#54595a'}}>
                  <ReactMarkdown>{feature.text}</ReactMarkdown>
                </div>
              </div>
            ))
          }
        </FeatureGrid>
      </Wrapper>
    </Container>
  );
};
